.button {
    padding: 10px 40px;
    color: white;
    background-color: #0644c0;
    font-family: 'Chakra Petch';
    font-weight: bold;
    font-size: 16px;
    border-radius: 0;
    border: 0.5px solid gray;
    width: 100%;
}

.buttonEnabled {
    padding: 10px 40px;
    color: gray;
    background-color: transparent;
    font-family: 'Chakra Petch';
    font-weight: bold;
    font-size: 16px;
    border-radius: 0;
    border: 1px solid gray;
    width: 100%;
}