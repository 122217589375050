#App {
  background-image: url("../public/earth/panorama_image.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#Earth {
  display: flex;
  flex: 1;
}